/**
 * Иторум API для портала
 * Спецификация Open API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs";

import { AnswerAnswerIdBody } from "../model/answerAnswerIdBody";
import { ApiTestingBody } from "../model/apiTestingBody";
import { InlineResponse20062 } from "../model/inlineResponse20062";
import { InlineResponse20063 } from "../model/inlineResponse20063";
import { InlineResponse20064 } from "../model/inlineResponse20064";
import { InlineResponse20065 } from "../model/inlineResponse20065";
import { InlineResponse20066 } from "../model/inlineResponse20066";
import { InlineResponse20067 } from "../model/inlineResponse20067";
import { InlineResponse20068 } from "../model/inlineResponse20068";
import { InlineResponse20069 } from "../model/inlineResponse20069";
import { QuestionIdAnswersBody } from "../model/questionIdAnswersBody";
import { QuestionIdSetsystemresultBody } from "../model/questionIdSetsystemresultBody";
import { QuestionIdSetuserresultBody } from "../model/questionIdSetuserresultBody";
import { QuestionQuestionIdBody } from "../model/questionQuestionIdBody";
import { TestingGeneratefromactiontemplateBody } from "../model/testingGeneratefromactiontemplateBody";
import { TestingQuestionBody } from "../model/testingQuestionBody";
import { TestingTestIdBody } from "../model/testingTestIdBody";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";
import { SystemresultAddimgBody } from "../model/systemresultAddimgBody";


@Injectable()
export class TestingService {

  protected basePath = "https://dev.itorummr.com";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Set test hierarchy by action id
   *
   * @param actionId Action id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingByActionActionIdGet(actionId: number, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20063>;
  public apiTestingByActionActionIdGet(actionId: number, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20063>>;
  public apiTestingByActionActionIdGet(actionId: number, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20063>>;
  public apiTestingByActionActionIdGet(actionId: number, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (actionId === null || actionId === undefined) {
      throw new Error("Required parameter actionId was null or undefined when calling apiTestingByActionActionIdGet.");
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20063>("get", `${this.basePath}/api/testing/by-action/${encodeURIComponent(String(actionId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Set test hierarchy by action template id
   *
   * @param actionTemplateId Action template id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingByActionTemplateActionTemplateIdGet(actionTemplateId: number, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20063>;
  public apiTestingByActionTemplateActionTemplateIdGet(actionTemplateId: number, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20063>>;
  public apiTestingByActionTemplateActionTemplateIdGet(actionTemplateId: number, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20063>>;
  public apiTestingByActionTemplateActionTemplateIdGet(actionTemplateId: number, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (actionTemplateId === null || actionTemplateId === undefined) {
      throw new Error("Required parameter actionTemplateId was null or undefined when calling apiTestingByActionTemplateActionTemplateIdGet.");
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20063>("get", `${this.basePath}/api/testing/by-action-template/${encodeURIComponent(String(actionTemplateId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Generate testing from action template
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingGenerateFromActionTemplatePost(body?: TestingGeneratefromactiontemplateBody, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20063>;
  public apiTestingGenerateFromActionTemplatePost(body?: TestingGeneratefromactiontemplateBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20063>>;
  public apiTestingGenerateFromActionTemplatePost(body?: TestingGeneratefromactiontemplateBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20063>>;
  public apiTestingGenerateFromActionTemplatePost(body?: TestingGeneratefromactiontemplateBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20063>("post", `${this.basePath}/api/testing/generate-from-action-template`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create testing
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingPost(body?: ApiTestingBody, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20062>;
  public apiTestingPost(body?: ApiTestingBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20062>>;
  public apiTestingPost(body?: ApiTestingBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20062>>;
  public apiTestingPost(body?: ApiTestingBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20062>("post", `${this.basePath}/api/testing`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete answer
   *
   * @param answerId Answer id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingQuestionAnswerAnswerIdDelete(answerId: number, observe?: "body", reportProgress?: boolean): Observable<any>;
  public apiTestingQuestionAnswerAnswerIdDelete(answerId: number, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<any>>;
  public apiTestingQuestionAnswerAnswerIdDelete(answerId: number, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<any>>;
  public apiTestingQuestionAnswerAnswerIdDelete(answerId: number, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (answerId === null || answerId === undefined) {
      throw new Error("Required parameter answerId was null or undefined when calling apiTestingQuestionAnswerAnswerIdDelete.");
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>("delete", `${this.basePath}/api/testing/question/answer/${encodeURIComponent(String(answerId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update question answers
   *
   * @param answerId Answer id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingQuestionAnswerAnswerIdPut(answerId: number, body?: AnswerAnswerIdBody, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20067>;
  public apiTestingQuestionAnswerAnswerIdPut(answerId: number, body?: AnswerAnswerIdBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20067>>;
  public apiTestingQuestionAnswerAnswerIdPut(answerId: number, body?: AnswerAnswerIdBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20067>>;
  public apiTestingQuestionAnswerAnswerIdPut(answerId: number, body?: AnswerAnswerIdBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (answerId === null || answerId === undefined) {
      throw new Error("Required parameter answerId was null or undefined when calling apiTestingQuestionAnswerAnswerIdPut.");
    }


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20067>("put", `${this.basePath}/api/testing/question/answer/${encodeURIComponent(String(answerId))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create question
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingQuestionPost(body?: TestingQuestionBody, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20066>;
  public apiTestingQuestionPost(body?: TestingQuestionBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20066>>;
  public apiTestingQuestionPost(body?: TestingQuestionBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20066>>;
  public apiTestingQuestionPost(body?: TestingQuestionBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20066>("post", `${this.basePath}/api/testing/question`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get question answers
   *
   * @param questionId Question id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingQuestionQuestionIdAnswersGet(questionId: number, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20067>;
  public apiTestingQuestionQuestionIdAnswersGet(questionId: number, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20067>>;
  public apiTestingQuestionQuestionIdAnswersGet(questionId: number, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20067>>;
  public apiTestingQuestionQuestionIdAnswersGet(questionId: number, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (questionId === null || questionId === undefined) {
      throw new Error("Required parameter questionId was null or undefined when calling apiTestingQuestionQuestionIdAnswersGet.");
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20067>("get", `${this.basePath}/api/testing/question/${encodeURIComponent(String(questionId))}/answers`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create question answers
   *
   * @param questionId Question id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingQuestionQuestionIdAnswersPost(questionId: number, body?: QuestionIdAnswersBody, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20067>;
  public apiTestingQuestionQuestionIdAnswersPost(questionId: number, body?: QuestionIdAnswersBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20067>>;
  public apiTestingQuestionQuestionIdAnswersPost(questionId: number, body?: QuestionIdAnswersBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20067>>;
  public apiTestingQuestionQuestionIdAnswersPost(questionId: number, body?: QuestionIdAnswersBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (questionId === null || questionId === undefined) {
      throw new Error("Required parameter questionId was null or undefined when calling apiTestingQuestionQuestionIdAnswersPost.");
    }


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20067>("post", `${this.basePath}/api/testing/question/${encodeURIComponent(String(questionId))}/answers`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete quetion and his answers
   *
   * @param questionId Question id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingQuestionQuestionIdDelete(questionId: number, observe?: "body", reportProgress?: boolean): Observable<any>;
  public apiTestingQuestionQuestionIdDelete(questionId: number, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<any>>;
  public apiTestingQuestionQuestionIdDelete(questionId: number, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<any>>;
  public apiTestingQuestionQuestionIdDelete(questionId: number, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (questionId === null || questionId === undefined) {
      throw new Error("Required parameter questionId was null or undefined when calling apiTestingQuestionQuestionIdDelete.");
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>("delete", `${this.basePath}/api/testing/question/${encodeURIComponent(String(questionId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update question
   *
   * @param questionId Question id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingQuestionQuestionIdPut(questionId: number, body?: QuestionQuestionIdBody, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20066>;
  public apiTestingQuestionQuestionIdPut(questionId: number, body?: QuestionQuestionIdBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20066>>;
  public apiTestingQuestionQuestionIdPut(questionId: number, body?: QuestionQuestionIdBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20066>>;
  public apiTestingQuestionQuestionIdPut(questionId: number, body?: QuestionQuestionIdBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (questionId === null || questionId === undefined) {
      throw new Error("Required parameter questionId was null or undefined when calling apiTestingQuestionQuestionIdPut.");
    }


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20066>("put", `${this.basePath}/api/testing/question/${encodeURIComponent(String(questionId))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Set system result
   *
   * @param questionId Question id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingQuestionQuestionIdSetSystemResultPost(questionId: number, body?: QuestionIdSetsystemresultBody, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20068>;
  public apiTestingQuestionQuestionIdSetSystemResultPost(questionId: number, body?: QuestionIdSetsystemresultBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20068>>;
  public apiTestingQuestionQuestionIdSetSystemResultPost(questionId: number, body?: QuestionIdSetsystemresultBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20068>>;
  public apiTestingQuestionQuestionIdSetSystemResultPost(questionId: number, body?: QuestionIdSetsystemresultBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (questionId === null || questionId === undefined) {
      throw new Error("Required parameter questionId was null or undefined when calling apiTestingQuestionQuestionIdSetSystemResultPost.");
    }


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20068>("post", `${this.basePath}/api/testing/question/${encodeURIComponent(String(questionId))}/set-system-result`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Set user result
   *
   * @param questionId Question id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingQuestionQuestionIdSetUserResultPut(questionId: number, body?: QuestionIdSetuserresultBody, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20069>;
  public apiTestingQuestionQuestionIdSetUserResultPut(questionId: number, body?: QuestionIdSetuserresultBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20069>>;
  public apiTestingQuestionQuestionIdSetUserResultPut(questionId: number, body?: QuestionIdSetuserresultBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20069>>;
  public apiTestingQuestionQuestionIdSetUserResultPut(questionId: number, body?: QuestionIdSetuserresultBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (questionId === null || questionId === undefined) {
      throw new Error("Required parameter questionId was null or undefined when calling apiTestingQuestionQuestionIdSetUserResultPut.");
    }


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20069>("put", `${this.basePath}/api/testing/question/${encodeURIComponent(String(questionId))}/set-user-result`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update question result img
   *
   * @param questionId Question id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingQuestionQuestionIdSystemResultAddImgPut(questionId: number, body?: SystemresultAddimgBody, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20065>;
  public apiTestingQuestionQuestionIdSystemResultAddImgPut(questionId: number, body?: SystemresultAddimgBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20065>>;
  public apiTestingQuestionQuestionIdSystemResultAddImgPut(questionId: number, body?: SystemresultAddimgBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20065>>;
  public apiTestingQuestionQuestionIdSystemResultAddImgPut(questionId: number, body?: SystemresultAddimgBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (questionId === null || questionId === undefined) {
      throw new Error('Required parameter questionId was null or undefined when calling apiTestingQuestionQuestionIdSystemResultAddImgPut.');
    }


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20065>('put',`${this.basePath}/api/testing/question/${encodeURIComponent(String(questionId))}/system-result/add-img`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }



  /**
   * Delete test
   *
   * @param testId Test id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingTestIdDelete(testId: number, observe?: "body", reportProgress?: boolean): Observable<any>;
  public apiTestingTestIdDelete(testId: number, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<any>>;
  public apiTestingTestIdDelete(testId: number, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<any>>;
  public apiTestingTestIdDelete(testId: number, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (testId === null || testId === undefined) {
      throw new Error("Required parameter testId was null or undefined when calling apiTestingTestIdDelete.");
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>("delete", `${this.basePath}/api/testing/${encodeURIComponent(String(testId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Finish test
   *
   * @param testId Test id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingTestIdFinishGet(testId: number, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20064>;
  public apiTestingTestIdFinishGet(testId: number, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20064>>;
  public apiTestingTestIdFinishGet(testId: number, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20064>>;
  public apiTestingTestIdFinishGet(testId: number, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (testId === null || testId === undefined) {
      throw new Error("Required parameter testId was null or undefined when calling apiTestingTestIdFinishGet.");
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20064>("get", `${this.basePath}/api/testing/${encodeURIComponent(String(testId))}/finish`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update test
   *
   * @param testId Test id
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingTestIdPut(testId: number, body?: TestingTestIdBody, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20062>;
  public apiTestingTestIdPut(testId: number, body?: TestingTestIdBody, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20062>>;
  public apiTestingTestIdPut(testId: number, body?: TestingTestIdBody, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20062>>;
  public apiTestingTestIdPut(testId: number, body?: TestingTestIdBody, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (testId === null || testId === undefined) {
      throw new Error("Required parameter testId was null or undefined when calling apiTestingTestIdPut.");
    }


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json"
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<InlineResponse20062>("put", `${this.basePath}/api/testing/${encodeURIComponent(String(testId))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create testing
   *
   * @param testId Test id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingTestIdQuestionsGet(testId: number, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20065>;
  public apiTestingTestIdQuestionsGet(testId: number, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20065>>;
  public apiTestingTestIdQuestionsGet(testId: number, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20065>>;
  public apiTestingTestIdQuestionsGet(testId: number, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (testId === null || testId === undefined) {
      throw new Error("Required parameter testId was null or undefined when calling apiTestingTestIdQuestionsGet.");
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20065>("get", `${this.basePath}/api/testing/${encodeURIComponent(String(testId))}/questions`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public apiTestingByActionTemplateIdGet(actionTemplateId: number, observe?: "body", reportProgress?: boolean): Observable<any>;
  public apiTestingByActionTemplateIdGet(actionTemplateId: number, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<any>>;
  public apiTestingByActionTemplateIdGet(actionTemplateId: number, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<any>>;
  public apiTestingByActionTemplateIdGet(actionTemplateId: number, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (actionTemplateId === null || actionTemplateId === undefined) {
      throw new Error("Required parameter actionTemplateId was null or undefined when calling apiTestingByActionTemplateIdGet.");
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>("get", `${this.basePath}/api/testing/by-action-template/${encodeURIComponent(String(actionTemplateId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   * Start test
   *
   * @param testId Test id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiTestingTestIdStartGet(testId: number, observe?: "body", reportProgress?: boolean): Observable<InlineResponse20064>;
  public apiTestingTestIdStartGet(testId: number, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<InlineResponse20064>>;
  public apiTestingTestIdStartGet(testId: number, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<InlineResponse20064>>;
  public apiTestingTestIdStartGet(testId: number, observe: any = "body", reportProgress: boolean = false): Observable<any> {

    if (testId === null || testId === undefined) {
      throw new Error("Required parameter testId was null or undefined when calling apiTestingTestIdStartGet.");
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === "function"
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json"
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InlineResponse20064>("get", `${this.basePath}/api/testing/${encodeURIComponent(String(testId))}/start`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
