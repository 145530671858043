import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { PermissionRolesService } from 'app/common/services/permission-roles.service';
import { PermissionService, UserService } from 'app/shared';
import { switchMap, catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleRedirectGuard implements CanActivate {

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private permissionRolesService: PermissionRolesService,
    private userService: UserService,
    private permissionService: PermissionService,
  ) { }
  private roles: any;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.client$.pipe(
      switchMap(client => {
        this.roles = this.activatedRoute.snapshot.data?.roles || {};
        console.log(this.roles);

        this.roles = {
          isManager: this.permissionRolesService.isManager(client),
          isAdmin: this.permissionRolesService.isAdmin(client),
          isDeveloper: this.permissionRolesService.isDeveloper(client),
          isTechnician: this.permissionRolesService.isTechnician(client),
          isRemoteExpert: this.permissionRolesService.isRemoteExpert(client)
        };
        console.log(this.roles);

        const roleLinksMap = {
          isRemoteExpert: [
            { routerLink: 'conference', labelKey: 'curriculum.main.conference' },
            { routerLink: 'courses', labelKey: 'curriculum.main.courses' }
          ],
          isTechnician: [
            { routerLink: 'courses', labelKey: 'curriculum.main.courses' }
          ],
          isManager: [
            { routerLink: 'content', labelKey: 'curriculum.main.content' },
            { routerLink: 'make-courses', labelKey: 'curriculum.main.make-courses' },
            { routerLink: 'home', labelKey: 'curriculum.main.home' }
          ],
          isAdmin: [
            {
              routerLink: 'management',
              labelKey: 'curriculum.main.administrate',
              subItems: [
                { routerLink: 'management/devices', labelKey: 'curriculum.main.devices' },
                { routerLink: 'management/users', labelKey: 'curriculum.main.users' },
                { routerLink: 'management/groups', labelKey: 'curriculum.main.groups' },
                { routerLink: 'management/competences', labelKey: 'curriculum.main.competences' }
              ]
            }
          ],
          isDeveloper: [
            { routerLink: 'content', labelKey: 'curriculum.main.content' },
            { routerLink: 'make-courses', labelKey: 'curriculum.main.make-courses' },
            { routerLink: 'home', labelKey: 'curriculum.main.home' }
          ]
        };

        let links = [];

        for (const role in this.roles) {
          if (this.roles[role] && roleLinksMap[role]) {
            links = this.sort(links);
            links.push(...roleLinksMap[role]);
          }
        }

        if (links.length === 0) {
          links = this.sort(links);
          links.push(
            { routerLink: 'courses', labelKey: 'curriculum.main.courses' },
          );
        }

        links = this.sort(links);

        console.log('Redirecting to:', links[links.length - 1].routerLink, state.url, links.length - 1, links.length, links);

        if (state.url === '/' || state.url === '' || state.url === '/curriculum') {
          return from(this.router.navigateByUrl(`/curriculum/${links[links.length - 1].routerLink}`)).pipe(
            map(() => false)
          );
        }

        return of(true);

      }),
      catchError(err => {
        console.error('getFullUserInfo in main =>', err);
        return of(false);
      })
    );
  }

  sort(event) {
    const sortOrder = [
      'curriculum.main.home',
      'curriculum.main.administrate',
      'curriculum.main.conference',
      'curriculum.main.make-courses',
      'curriculum.main.content',
      'curriculum.main.courses',
    ];
    event = event.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.routerLink === obj.routerLink
        ))
    );
    return event.sort((a, b) => sortOrder.indexOf(b.labelKey) - sortOrder.indexOf(a.labelKey));
  }
}
