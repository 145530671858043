import { Injectable } from '@angular/core';

interface IAuthInfo {
  authType: string;
  authToken: string;
}

@Injectable()
export class StorageService {
  public isLoggedIn(): boolean {
    return this.getAuthInfo().authToken ? true : false;
  }

  public getAuthToken(): string {
    const { authType, authToken } = this.getAuthInfo();
    return authType + ' ' + authToken;
  }

  public getAuthInfo(): IAuthInfo {
    return (
      this.get<IAuthInfo>('access_token') || {
        authToken: undefined,
        authType: undefined
      }
    );
  }

  public setAuthInfo(info: IAuthInfo) {
    this.set<IAuthInfo>('access_token', info);
  }

  public getCurrentLanguage(defaultLang) {
    const lang = localStorage.getItem('lang');
    if (!lang) {
      return defaultLang;
    }
    return lang;
  }

  /** create one if no exists */
  public getDeviceSerial(): string {
    const deviceSerial = this.get<string>('device-serial');
    if (deviceSerial) {
      return deviceSerial;
    }
    const newDeviseSerial = [
      Math.random()
        .toString(36)
        .substring(2, 15),
      '-',
      Math.random()
        .toString(36)
        .substring(2, 15)
    ].join('');
    this.set('device-serial', newDeviseSerial);
    return this.getDeviceSerial();
  }

  public set<T>(key: string, data: T): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  public get<T>(key: string): T {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.error(e);
    }
    return null;
  }

  public remove(key: string): void {
    localStorage.removeItem(key);
  }

  public clearAuth() {
    localStorage.removeItem('access_token');
  }

  public setCurrentLanguage(lang: string) {
    localStorage.setItem('lang', lang);
  }
}
