import {
  APP_INITIALIZER,
  Injectable,
  Injector,
  NgModule,
  LOCALE_ID, forwardRef
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouteReuseStrategy, RouterModule } from "@angular/router";
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import './shared/rxjs-operators';
import { AppComponent } from './app.component';

import {
  ApiService,
  UserService,
  PermissionService,
  UniversalGuard,
  AppInterceptor,
  GalleryService,
  ImageService,
  SystemSettingsService,
  SideModalService
} from './shared';

import { registerLocaleData } from '@angular/common';
import { UiModule } from "@itorum/ui";
import { StorageService } from "@itorum/services";

// Locale imports
import localeRu from '@angular/common/locales/ru';
import { AppConfigService } from "@shared/services/app-config.service";
import { ThemeService } from "@shared/services/theme.service";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { I18nTranslateLoader } from "@shared/utils/translate-loader";
import { TimeShiftPreloadingStrategy } from './loading-strategy';
import { ToastrInviteDialogComponent } from './common/services/comonents/toastr-invite-dialog/toastr-invite-dialog.component';
import { ToastrCallDialogComponent } from './common/services/comonents/toastr-call-dialog/toastr-call-dialog.component';
import { ToastrDoCallDialogComponent } from './common/services/comonents/toastr-do-call-dialog/toastr-do-call-dialog.component';
import { LeaveRoomDialogComponent } from './common/services/comonents/leave-room-dialog/leave-room-dialog.component';
import { JanusService } from './common/services/janus.service';
import { SharedModule } from "@shared/shared.module";
import { AuthGuard } from "@shared/guards/auth.guard";
import { PermissionRolesService } from "app/common/services/permission-roles.service";
import { LayoutService } from "app/common/services/layout.service";
import { ANOTHER_BASE_PATH, BASE_PATH, RemoteExpert2Service, StepDefectsService, VOICE_BASE_PATH } from '@itorum/api';
import { RoomStrategy } from "app/+conference/strategies/room.strategy";
import { CurriculumGuard } from "@shared/guards/curriculum.guard";
import { OopsComponent } from './common/components/oops/oops.component';
import { RoleRedirectGuard } from 'app/curriculum/guards/role-redirect.guard';


registerLocaleData(localeRu);
@Injectable()
export class UpdateUserInfo {
  constructor(private injector: Injector) {}

  updateUserInfo() {
    const token = this.injector.get(StorageService).getAuthInfo().authToken;
    if (token) {
      const userService = this.injector.get<UserService>(UserService);
      return userService.getFullUserInfo().subscribe();
    }
  }
}

export const initialize = (
  appConfigService: AppConfigService,
  uui: UpdateUserInfo,
  permissionRolesService: PermissionRolesService
) => {
  return () => {
    async function init() {
      const appConfig = await appConfigService.initAppSettings();
      await permissionRolesService.initRoles(appConfig);
      return uui.updateUserInfo();
    }
    return init();
  };
};

export const getBackendPath = (
  appConfigService: AppConfigService
) => {
  return `https://${appConfigService.appConfig.backendHostname}`;
}

@NgModule({
  declarations: [
    AppComponent,
    ToastrInviteDialogComponent,
    ToastrCallDialogComponent,
    ToastrDoCallDialogComponent,
    LeaveRoomDialogComponent,
    OopsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    UiModule,
    SharedModule,
    RouterModule.forRoot([
      {
        path: '',
        canActivate: [RoleRedirectGuard],
        children: [
          {
            path: '',
            loadChildren: () => import('./curriculum/curriculum.module').then(m => m.CurriculumModule)
          }
        ]
      },
      {
        path: "curriculum",
        loadChildren: () => import("./curriculum/curriculum.module").then(m => m.CurriculumModule),
        data: { preload: false, delay: true },
        canActivate: [AuthGuard, CurriculumGuard]
      },
      {
        path: "account",
        loadChildren: () => import("./account/account.module").then(m => m.AccountModule),
        data: { preload: true, delay: true },
        canActivate: [AuthGuard]
      },
      {
        path: "oops",
        component: OopsComponent
      }
    ], {
      preloadingStrategy: TimeShiftPreloadingStrategy,
      enableTracing: false,
      relativeLinkResolution: "legacy"
    }),
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: I18nTranslateLoader
      }
    })
  ],
  providers: [
    StorageService,
    StepDefectsService,
    ApiService,
    UniversalGuard,
    AuthGuard,
    RemoteExpert2Service,
    UserService,
    PermissionService,
    UpdateUserInfo,
    ThemeService,
    GalleryService,
    ImageService,
    SystemSettingsService,
    SideModalService,
    AppConfigService,
    TimeShiftPreloadingStrategy,
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      deps: [AppConfigService, UpdateUserInfo, PermissionRolesService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: "ru"
    },
    {
      provide: BASE_PATH,
      useFactory: getBackendPath,
      deps: [AppConfigService],
      multi: false
    },
    { provide: ANOTHER_BASE_PATH, useValue: 'https://voice-services.itorummr.com' },
    { provide: VOICE_BASE_PATH, useValue: 'https://dev.voice-services.itorummr.com' },
    { provide: RouteReuseStrategy, useClass: RoomStrategy },
    forwardRef(() => JanusService),
    forwardRef(() => PermissionRolesService),
    forwardRef(() => LayoutService)
  ],
  entryComponents: [
    ToastrInviteDialogComponent,
    ToastrCallDialogComponent,
    ToastrDoCallDialogComponent,
    LeaveRoomDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
